import { type FontProps } from '@/screens/Chamber/components/ThemePanel/types';

export const fonts: FontProps[] = [
  { name: "Suisse Int'l", value: '"Suisse Int\'l", sans-serif' },
  { name: 'Alegreya', value: "'Alegreya', serif" },
  { name: 'Alegreya Sans', value: "'Alegreya Sans', sans-serif" },
  { name: 'Archivo Narrow', value: "'Archivo Narrow', sans-serif" },
  { name: 'BioRhyme', value: "'BioRhyme', serif" },
  { name: 'Cardo', value: "'Cardo', serif" },
  { name: 'Chivo', value: "'Chivo', sans-serif" },
  { name: 'Cormorant', value: "'Cormorant', serif" },
  { name: 'DM Sans', value: "'DM Sans', sans-serif" },
  { name: 'Eczar', value: "'Eczar', serif" },
  { name: 'Fira Sans', value: "'Fira Sans', sans-serif" },
  { name: 'Inconsolata', value: "'Inconsolata', monospace" },
  { name: 'Inknut Antiqua', value: "'Inknut Antiqua', serif" },
  { name: 'Karla', value: "'Karla', sans-serif" },
  { name: 'Lato', value: "'Lato', sans-serif" },
  { name: 'Libre Baskerville', value: "'Libre Baskerville', serif" },
  { name: 'Libre Franklin', value: "'Libre Franklin', sans-serif" },
  { name: 'Lora', value: "'Lora', serif" },
  { name: 'Manrope', value: "'Manrope', sans-serif" },
  { name: 'Merriweather', value: "'Merriweather', serif" },
  { name: 'Montserrat', value: "'Montserrat', sans-serif" },
  { name: 'Neuton', value: "'Neuton', serif" },
  { name: 'Open Sans', value: "'Open Sans', sans-serif" },
  { name: 'Playfair Display', value: "'Playfair Display', serif" },
  { name: 'Poppins', value: "'Poppins', sans-serif" },
  { name: 'PT Sans', value: "'PT Sans', sans-serif" },
  { name: 'PT Serif', value: "'PT Serif', serif" },
  { name: 'Roboto', value: "'Roboto', sans-serif" },
  { name: 'Roboto Slab', value: "'Roboto Slab', serif" },
  { name: 'Rubik', value: "'Rubik', sans-serif" },
  { name: 'Source Sans Pro', value: "'Source Sans Pro', sans-serif" },
  { name: 'Source Serif Pro', value: "'Source Serif Pro', serif" },
  { name: 'Space Grotesk', value: "'Space Grotesk', sans-serif" },
  { name: 'Space Mono', value: "'Space Mono', monospace" },
  { name: 'Syne', value: "'Syne', sans-serif" },
  { name: 'Work Sans', value: "'Work Sans', sans-serif" },
];
