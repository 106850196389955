import styled from 'styled-components';

import { EventFieldRow } from '@/features/events/styles';

export const Container = styled(EventFieldRow)`
  padding: 0;
  border: none;
`;

export const EventRepeatWrapper = styled.div`
  flex: 1;
`;
