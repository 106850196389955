import { type OptionType } from './types';

export const visibilityLevel = {
  Secret: 1,
  Private: 2,
  Public: 3,
};

export const visibilityLevelReverse: {
  [key: number]: string;
} = Object.entries(visibilityLevel).reduce(
  (a, b) => ({
    ...a,
    [b[1]]: b[0],
  }),
  {},
);

export const options: OptionType[] = [
  {
    name: 'NFT Worldwide',
    connections: 15,
    followers: 5,
    visibility: 'Public',
    checked: false,
    type: 'Project',
    linked: 0,
    spaceId: '1',
    disabled: false,
    profileImage: '',
    isSubNoum: false,
    key: '1',
    linkedNoums: [],
  },
  {
    name: 'Personal Branding',
    connections: 4,
    followers: 3,
    visibility: 'Private',
    checked: false,
    type: 'Project',
    linked: 0,
    spaceId: '2',
    disabled: false,
    profileImage: '',
    isSubNoum: false,
    key: '2',
    linkedNoums: [],
  },
  {
    name: 'Drone Prototype Project',
    connections: 25,
    followers: 1,
    visibility: 'Public',
    checked: false,
    type: 'Project',
    linked: 0,
    spaceId: '3',
    disabled: false,
    profileImage: '',
    isSubNoum: false,
    key: '3',
    linkedNoums: [],
  },
  {
    name: 'Copyright Law 101',
    connections: 250,
    followers: 2,
    visibility: 'Secret',
    checked: false,
    type: 'Project',
    linked: 3,
    spaceId: '4',
    disabled: false,
    profileImage: '',
    isSubNoum: false,
    key: '4',
    linkedNoums: [],
  },
  {
    name: 'Blockchain Development Noum',
    connections: 15,
    followers: 5,
    visibility: 'Public',
    checked: false,
    type: 'Project',
    linked: 0,
    spaceId: '5',
    disabled: false,
    profileImage: '',
    isSubNoum: false,
    key: '5',
    linkedNoums: [],
  },
  {
    name: 'Drone Engineering Small Talk',
    connections: 15,
    followers: 5,
    visibility: 'Secret',
    checked: false,
    type: 'Project',
    linked: 2,
    spaceId: '6',
    disabled: false,
    profileImage: '',
    isSubNoum: false,
    key: '6',
    linkedNoums: [],
  },
];
