export default {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  EXPIRES_IN: 'EXPIRES_IN',
  USER: 'USER',
  SB_CV: 'SB_CV',
  SHOW_BANNER: 'SHOW_BANNER',
  EVENT_SENT_SIGN_UP_APPROVED: 'EVENT_SENT_SIGN_UP_APPROVED',
  GUEST_REDIRECT_TO_URI: 'GUEST_REDIRECT_TO_URI',
  ALERT_NOTIFICATIONS: 'ALERT_NOTIFICATIONS',
  QUICK_SIGNUP_NOUM_ID: 'QUICK_SIGNUP_NOUM_ID',
  SIDE_NAVIGATION_EXPANDED: 'SIDE_NAVIGATION_EXPANDED',
  GENIUS_ASSIST_FIRST_TIME: 'GENIUS_ASSIST_FIRST_TIME',
} as const;
